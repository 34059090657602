import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { TECHNICAL_ANALYSIS_LANDING_PAGE } from 'constants/pages';
import Link from 'components/Link';

export default function NewProduct() {
  return (
    <div className="mx-auto w-full max-w-7xl px-4 py-10">
      <div className="mx-auto py-10">
        <div className="relative mx-auto rounded-xl border-4 border-brand-primary bg-palette-black-4 p-4 md:p-12">
          <div className="mx-auto grid max-w-7xl grid-cols-1 items-center gap-10 lg:grid-cols-12 lg:gap-8">
            <div className="tracking-tight text-white lg:col-span-9">
              <h5 className="py-1 text-xl font-bold">
                NEW: Buy Automated Technical Analysis (without the dashboard)
              </h5>
              <p className="text-base text-[#c2c3c5]">
                Only interested in our technical analysis tools? We got you covered. The
                InsiderFinance automated technical analysis system can now be purchased separately
                without a dashboard subscription.
              </p>
            </div>
            <div className="w-full items-center text-right lg:col-span-3">
              <Link
                href={TECHNICAL_ANALYSIS_LANDING_PAGE}
                className="inline-flex gap-x-1.5 rounded-md  bg-[#30a84f] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Get Technical Analysis System <ArrowNarrowRightIcon className="-mr-0.5 h-5 w-5" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
